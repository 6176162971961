/* eslint-disable*/
import VueStoreService from './VueStoreService';
const CategoryService = {
    async getCategorys(filter,store){
        var resp = await VueStoreService.get("category"+filter, store)
        return resp;
    },
    async saveCategory(data,store){
        var resp = await VueStoreService.post("category",data,store)
        return resp;
    },
    async getCategoryById(category_id,store){
        var resp = await VueStoreService.get( "category/"+category_id,store)
        return resp;
    },
    async updateCategory(category_id,data,store){
        var resp = await VueStoreService.put("category/"+category_id,data,store)
        return resp;
    },
    async validateDeleteCategory (category_id,store){
        var resp = await VueStoreService.get("category/canDeleteCat/" + category_id,store)
        return resp;
    },
    async deleteCategory(category_id,store){
        var resp = await VueStoreService.delete("category/"+category_id,store)
        return resp;
    },
}

export default CategoryService;