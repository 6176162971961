/* eslint-disable*/
import VueStoreService from './VueStoreService';
const SpecialityService = {
    async getSpecialitys(filter,store){
        var resp = await VueStoreService.get("speciality"+filter, store)
        return resp;
    },
    async saveSpeciality(data,store){
        var resp = await VueStoreService.post("speciality",data,store)
        return resp;
    },
    async getSpecialityById(speciality_id,store){
        var resp = await VueStoreService.get( "speciality/"+speciality_id,store)
        return resp;
    },
    async updateSpeciality(speciality_id,data,store){
        var resp = await VueStoreService.put("speciality/"+speciality_id,data,store)
        return resp;
    },
    async validateDeleteSpeciality(speciality_id,store){
        var resp = await VueStoreService.get("speciality/canDeleteSpeciality/" + speciality_id,store)
        return resp;
    },
    async deleteSpeciality(speciality_id,store){
        var resp = await VueStoreService.delete("speciality/"+speciality_id,store)
        return resp;
    },
}

export default SpecialityService;