<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <filters ref="filters" />
      <b-card no-body>
        <b-table
          class="position-relative"
          :hover="true"
          :items="records"
          responsive
          :fields="fields"
          show-empty
          empty-text="No existen"
          @sort-changed="sortChanged"
          no-border-collapse
        >
          <!-- Column: Actions -->
          <template #cell(project)="data">
            <span>{{data.item.project.code}} - {{data.item.project.description}}</span>
          </template>
          <template #cell(actions)="data">
            <b-button
              size="sm"
              class=""
              @click.prevent="show(data.item)"
              v-b-tooltip.noninteractive.hover.top="'Ver Proyectos'"
              variant="flat-success"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              size="sm"
              class=""
              @click.prevent="edit(data.item)"
              v-b-tooltip.noninteractive.hover.top="'Editar'"
              variant="flat-success"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              size="sm"
              class=""
              v-b-tooltip.noninteractive.hover.top="'Eliminar'"
              @click="deleteAction(data.item)"
              variant="flat-danger"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group label-cols="4" label-cols-md="4" label-size="md" label="Entradas" label-for="input-md">
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal ref="projectsGroup" centered title="Proyectos" size="xl" hide-footer @hidden="resetModalProjects()">
        <b-row v-if="recordsProjects.length > 0">
          <b-col v-for="(item, index) in recordsProjects" :key="index" cols="12" sm="6" md="4" lg="3">
            <div class="card_project d-flex flex-column mb-2">
              <div class="card_img">
                <img :src="item.url ? apiurl + item.url : sideImg" :alt="'imagen_project_' + index" />
              </div>
              <div class="card_content px-2 py-2 d-flex flex-column">
                <span class="card_content_sub">{{ item.code }}</span>
                <span class="card_content_title mb-2">{{ item.description }}</span>
                
              </div>
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
const APIURL = process.env.APIURLFILE
import Vue from 'vue'
import filters from './filters.vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SpecialityService from '@/services/SpecialityService'
import GrouppingService from '@/services/GrouppingService'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      showLoading: false,
      fields: [
        { key: 'code', label: 'Codigo', sortable: false },
        { key: 'description', label: 'Nombre', sortable: false },
        { key: 'actions', label: 'Acciones' }
      ],
      form: {
        code: '',
        description: '',
        project_id: [],
      },
      records: [],
      recordsProjects: [],
      documentSelect: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      apiurl: APIURL,
      sideImg: require('@/assets/images/access/default.png')
    }
  },
  components: {
    vSelect,
    filters
  },
  mounted() {
    this.getData()
  },
  methods: {
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    async getData() {
      this.showLoading = true
      const url = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=`+JSON.stringify(this.arrayFilters)
      const resSpec = await GrouppingService.getGroupping(url, this.$store)
      console.log('resSpec', resSpec)
      if (resSpec.status) {
        this.records = resSpec.data.rows
        this.totalElements=resSpec.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
    async show(item) {
      console.log('item',item)
      const respGroup = await GrouppingService.getGrouppingById(item.id, this.$store)
      console.log('respGroup', respGroup)
      this.recordsProjects = respGroup.data.projects
      this.$refs.projectsGroup.show()
    },
    resetModalProjects() {
      this.$refs.projectsGroup.hide()
      this.recordsProjects = []
    },
    edit(item) {
      console.log('item',item)
      this.form.id = item.id
      this.form.code = item.code
      this.form.description = item.description
      this.form.project_id = item.project_id
      console.log('this.form', this.form)
      this.$refs.filters.isAdd = true
      this.$refs.filters.$children[0].setData(this.form)
    },
    async deleteAction(data) {
      
      this.$swal({
      title: '¿Desea eliminar esta agrupación?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminalo',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1'
      },
      buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
      console.log('id', id)
      const resp = await GrouppingService.deleteGroupping(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
          this.$swal({
            icon: 'success',
            title: 'Eliminado!',
            text: 'La agrupación ha sido eliminado.',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar la agrupación seleccionada.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.card_project {
  border-radius: 15px;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  // background: linear-gradient(118deg, #001955, rgba(0, 25, 85, 0.7))
  background: linear-gradient(
    0deg,
    rgba(246, 249, 252, 1) 0%,
    rgba(234, 242, 249, 1) 48%,
    rgba(223, 235, 248, 1) 100%
  );
}
.card_project .card_content .card_content_sub {
  color: #59ccd0;
  font-weight: 600;
}
.card_project .card_content .card_content_title {
  font-size: 18px;
  font-weight: 600;
  color: #001955;
}
.card_img img {
  width: 100%;
  object-fit: cover;
  height: 200px;
  border-radius: 15px 15px 0px 0px;
  background-color: #fff !important;
}
.card_content .btn_edit:hover{
  background-color: transparent !important;
}
.card_content .btn_edit{
  color:  #001955;
  font-weight: 600;
}
</style>
